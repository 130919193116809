/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Wrapper from '../layouts/wrapper';
import Section from '../layouts/section';
import constants from '../components/constants';
import postData from '../components/poster';
import fallBackProductList from '../data/fallBackProductList.json';
import Radio from '../components/radio/radio-for-products';
import { GoBack } from '../components/GoBack';
import Field from '../components/field/field';
import SplitRepeater from '../components/field/SplitRepeater';
import Button from '../components/button/button';
const url = constants.api_url;
 
// import getCachedQuestions from './getCachedQuestions.js'
 
let disabled_products = ["204", "302", "164", "165", "166", "168", "10", "150", "167"];
let active_products = ["138", "9", "301", "300"];
// // let disable_plans = ["1208", "1209"];
// let disable_plans = [ ];

class EditTemplate extends Component {
  state = {
    data: [],
    loaded: false,
    yet_to_load: true,
  };
  
  componentDidMount(){
    window.scroll(0, 0);
    this.loadData();

  }

  // let mode = this.props.match.params.mode;

  loadData(){
    this.setState({
        
        loaded: false,
        
      });
    let agent_info = localStorage.getItem("agent_info");
    agent_info = JSON.parse(agent_info || "{}");
    
    if(!agent_info)return;
    postData
      .getData(url + "listSplitsAgent.php?for_agent=" + agent_info.id)
      .then(data => {
        data.list = data.list.map((item, i)=>{
          item.templateFlag = Math.random();
          return item;
        })
        localStorage.setItem('orange_agentTemplates', JSON.stringify(data.list));
        this.loadItem(data.list);
        this.setState({
          loaded:true,
          agent_info: agent_info
        })
      }).catch(err=>{
        let list = localStorage.getItem('orange_agentTemplates');
        list = JSON.parse(list||'[]')||[];
        this.loadItem(list);
        this.setState({
          loaded:true,
          agent_info: agent_info
        })

      })
  }

  loadItem(items){
    
    let sel_item = false;
    let i = this.props.match.params.id;
    
    i = parseInt(i);
    items.map((item)=>{
      if(item.id == i)
      {
        sel_item = item;
      }
    })

    if(sel_item)
    {
      
      this.setState({
        template_name : sel_item.template_name,
        agent_name : sel_item.printed_agent_name,
        group_name: sel_item.group_name || '',
        group_no: sel_item.group_no || '',
        agent_no : sel_item.agent_no,
        splits: JSON.parse(sel_item.split_settings || '[{},{},{},{},{}]')
      })
    }
  }
 
  doSubmitMain() {
    let agent_info = localStorage.getItem("agent_info");
    agent_info = JSON.parse(agent_info || "{}");
    let si = this.props.match.params.id;
    if(!agent_info)return;
    postData
    .postData(url + "createSplit.php?mode=update&id="+si+"&agent_id=" + agent_info.id, {
      agent_no: this.state.agent_no,
      split_settings: JSON.stringify(  this.state.splits),
      soliciting_agent_signature: this.state.agent_name, 
      group_name: this.state.group_name || '',
      group_no: this.state.group_no || '',
      template_name:    this.state.template_name,
      printed_agent_name:    this.state.agent_name
    })
    .then((data) => {
      if(!data.ok)
      {
        window.swal('Error', data.error || 'Failed to update agent template', 'error');
        return;
      } 
      window.swal('Success', "Agent template updated successfully!", 'success');

      let m = localStorage.getItem("orange_usingTemplateFlag");
      if(m && m!='default')
      {
        localStorage.setItem("orange_usingTemplateFlag", Math.random());
      }
     
      this.loadData();
      
    })
    .catch(function () {
      window.swal('Error', "Failed to update agent template!", 'error');
    });
  }


  
  handleChange(a, b)
  {
    this.setState({
        [a] : b
    })
  }
  handleChange1(a,b){
    let splits= this.state.splits;
    splits = JSON.parse(JSON.stringify(splits||[{},{},{},{}]));
    if(a == 'agent_name')
    {
      splits[0]['agent_split_first'] = (b||'').split(' ')[0];
      splits[0]['agent_split_last'] = ((b||'')+' ').split(' ')[1];
    }else if(a =='agent_no'){
      splits[0]['agent_split_no'] = b;
    }
    this.setState({
      [a] : b,
      splits : splits
  })
  }
  handleChange2(b, a)
  {
    this.setState({
        [a] : b,
        agent_name : b[0].agent_split_first + ' '+b[0].agent_split_last,
        agent_no : b[0]['agent_split_no']
    })
  }
  isDisabled(){
    let {template_name, agent_name, agent_no , splits} = this.state;
    this.error ='';
    if(!template_name  )
    {
        this.error = 'Please enter template name.';
        return false;
    }
    if(  !agent_no  )
    {
        this.error = 'Please enter agent number';
        return false;
    }
    if(  !agent_name)
    {
        this.error = 'Please enter agent name';
        return false;
    }

        
    if(splits)
    {
        let has_split = 0;
        let totalSplit = 0;
        let is_validError = false;
        splits.map((  i) => 
        {
            if(i.agent_split_per)
            {
                has_split++;
                totalSplit += parseInt(i.agent_split_per);
                if(!i.agent_split_first || !i.agent_split_last || !i.agent_split_no) 
                {
                    is_validError = true;
                }
            }
        });

        if(is_validError){
            this.error = 'Please enter all info of agents in splits';
            return false;
        }
        if(has_split)
        {
            if(totalSplit != 100)
            {
                this.error = 'Agent splits percentage should be equal 100%';
                return false;
            }
        }
    }
    return true;
  }
  render() {
    
    let loaded = this.state.loaded;
    let isDisabled = !this.isDisabled();
    return (
      <Wrapper>
        <div className="header_main">
          <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  alt="logo"
                  src={require('../assets/images/logo_top.png').default}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                  Edit Template
                </h1>

                <GoBack />
              </div>
            </Section.Head>
            
        {loaded  ?  
            <Section.Body>
              <div
                style={{margin: 8, background: 'none'}}
                className=" ">
                


                <div className={"form"}>
           
          <div className={"form__row"}>
            <div className="form__col">
              <Field name="template_name" value={this.state.template_name} label="Template name"  onChange={this.handleChange.bind(this)} />
            </div>
            <div className="form__col">
              <Field name="agent_name" value={this.state.agent_name} label="Agency  Agent Name"  onChange={this.handleChange1.bind(this)} />
            </div>
            <div className="form__col">
              <Field name="agent_no" value={this.state.agent_no} label="Agency Agent Number"  onChange={this.handleChange1.bind(this)} />
            </div>
            <div className="form__col">
              <Field name="group_no" value={this.state.group_no} label="Group No"  onChange={this.handleChange1.bind(this)} />
            </div>
            <div className="form__col">
              <Field name="group_name" value={this.state.group_name} label="Group Name"  onChange={this.handleChange1.bind(this)} />
            </div>
           
            <SplitRepeater  name={'splits'}
			   id={'splits'}
			   value={this.state.splits ||  [{},{},{},{}] } onChange={this.handleChange2.bind(this)}  />
           </div>
         </div>

<div class="text-center mt-3" style={{marginTop:30}}>
<button onClick={this.doSubmitMain.bind(this)} disabled={isDisabled} type="button" class={"btn   btn--medium" +(isDisabled? ' disabled' : '')} style={{fontSize:12, padding:10}} >Update Template</button>
</div>
<div class="text-center mt-3" style={{marginTop:10, color:'red', fontSize:12}}>

{
    this.error
}

</div>
<br />
<br />
<br />
              </div>
            </Section.Body>
 : (
  <div style={{textAlign:"center"}}>
  <img src={require('../assets/loading.gif').default}  />
  </div>
) }

            <Section.Foot></Section.Foot>
          </Section>
        </div>
      </Wrapper>
    );
  }
}

export default EditTemplate;
