/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Wrapper from '../layouts/wrapper';
import Section from '../layouts/section';
import constants from '../components/constants';
import postData from '../components/poster';
 
import { GoBack } from '../components/GoBack';
const url = constants.api_url;
  

class Templates extends Component {
  state = {
    data: [],
    loaded: false,
    yet_to_load: true,
  };


  constructor(props) {
    super(props);
     
  }
   
  componentDidMount() {
    this.loadData();
  }
  loadData(){
    this.setState({
        
        loaded: false,
        
      });
    let agent_info = localStorage.getItem("agent_info");
    agent_info = JSON.parse(agent_info || "{}");
    
    if(!agent_info)return;
    postData
      .getData(url + "listSplitsAgent.php?for_agent=" + agent_info.id)
      .then(data => {
        
        data.list = data.list.map((item, i)=>{
          item.templateFlag = Math.random();
          return item;
        })
        localStorage.setItem('orange_agentTemplates', JSON.stringify(data.list));
        this.setState({
          data: data.list,
          loaded: true,
          agent_info: agent_info
        });
      }).catch(err=>{
        let list = localStorage.getItem('orange_agentTemplates');
        list = JSON.parse(list||'[]')||[];
        this.setState({
          data:list, loaded:true, agent_info:agent_info
        })

      })
  }

  editItem(r){
    r.preventDefault();
    // window.swal('Yet to work on this. Will come to this if this new development works and approved')
  }

  deleteItem(id)
  {

    if(window.confirm('Are you sure you want to delete this template?'))
    {
        postData
      .getData(url + "listSplitsAgent.php?delete_id_app=" + id)
      .then(data => {
        window.swal('Success', "Template deleted successfully.", 'success');
        this.loadData();
      });
        
    }
  }
     
  render() {
   
    let {agent_info, data, loaded} = this.state;
    return (
      <Wrapper>
        <div className="header_main">
          <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  alt="logo"
                  src={require('../assets/images/logo_top.png').default}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                  Agent Templates
                </h1>
    {loaded ? 
                <GoBack />  :false}
              </div>
            </Section.Head>
            <Section.Body>
              <div
                style={{margin: 8, background: 'none'}}
                className=" ">
                



                {loaded  ? false : (
                  <div style={{textAlign:"center"}}>
                  <img src={require('../assets/loading.gif').default}  />
                  </div>
                ) }
                {loaded ? <>

                <div className="radio_card menu-sidebar">
                    <ul>
                        <li>
                            <strong><i>Default Template</i>
                            <span className="meta">{agent_info.name} - {agent_info.agent_number}</span></strong>
                         
                        </li>
                                        
                        {
                            data ? 
                            data.map((item, i)=>(
                                <li key={item.id}>
                                    <strong>
                                    <Link className="text-danger" onClick={(e)=>{
    e.preventDefault();
    this.deleteItem(item.id);
                                    }} to={'/edit-template/'+item.id}> <i className="fa fa-trash"></i></Link>
                                    <Link to={'/edit-template/'+item.id}> <i className="fa fa-pencil"></i>Edit</Link>
                                        
                                        {item.template_name}
                                        <span className="meta">{item.printed_agent_name} - {item.agent_no}</span>
                                    </strong>
                                </li>
                            )) : false
                        } 

                    </ul>
                 
                </div>
                <Link to="/add-template" onClick={e=>{
           
        }} style={{marginTop:10,fontSize:12,     width: '100%',
    textAlign: 'center',
    display: 'inline-block'}}>+ Add template</Link>
    <br />

<br />

                        
                        {/* <p className="align-center text-center">
                        <button    className="btn btn-success    btn-rounded "  onClick={()=>{
                            this.doSubmitMain();
                        }}>Save</button>   <Link
                        style={{ padding:15,background: 'var(--color-mode-button)', color: '#fff', borderRadius:50, marginLeft:15, width:55, height:55, display:'inline-block'}}
                        to="/inner">
                        <span className="fa fa-home"></span>
                      </Link>
                        </p> */}

                        </>
                        : false}


               
               
              </div>
            </Section.Body>

            <Section.Foot></Section.Foot>
          </Section>
        </div>
      </Wrapper>
    );
  }
}

export default Templates;
