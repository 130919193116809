import { Redirect, Route } from 'react-router-dom';
import {
  IonApp
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import './styles/style.scss';
import './launch_screens/scss/style.scss';

import Home from './pages/home';
import MainScreens from './pages/MainScreens';
import Steps from './pages/steps';
// import Tabs from './pages/tabs';
import Info from './pages/info';
import Submitted from './pages/submitted';
import PendingSubmissions from './pages/pendingSubmissions';
import CompletedSubmissions from './pages/completedSubmissions';
import FailedSubmissions from './pages/failedSubmissions';
// import Menu from './pages/menu';
import Colors from './pages/colors';
import ResumeApplication from './pages/ResumeApplication';
import ForceEmail from './pages/ForceEmail';
import Settings from './pages/settings';
import Products from './pages/products';
import { App as CApp, URLOpenListenerEvent } from '@capacitor/app';
import { AppProvider } from './app-provider';
import React, {useEffect} from 'react';

import AddTemplate from './pages/AddTemplate'
import EditTemplate from './pages/EditTemplate'
import Templates from './pages/Templates'


// CapacitorUpdater.notifyAppReady()

/* Theme variables */
// import './theme/variables.css';

const adjustHexOpacity = ( opacity) =>{
 
//  let rgba = {
//   r: 153,g: 153,b: 153, a: opacity
//  }
let rgba = {
  r: 85,g: 85,b: 85, a: opacity
 }
 let bg = {
  r: 250, g: 252, b: 252
 }
  const red = Math.round((1 - rgba.a) * bg.r + rgba.a * rgba.r);
  const green = Math.round((1 - rgba.a) * bg.g + rgba.a * rgba.g);
  const blue = Math.round((1 - rgba.a) * bg.b + rgba.a * rgba.b);

  return 'rgb('+red+', '+green+', '+blue+')';

}

const getColorFromSt = function(item)
{
  let tmp = localStorage.getItem('color_mode_'+item);
  if(! tmp || tmp === 'undefined')
  {
    if(item == 'radio_size_left')
    {
      return '14px';
    }
    if(item == 'radio_size_top')
    {
      return '17px';
    }
    if(item == 'show_progress')
    {
      return 'visible' ;
    }
    if(item == 'd_btn')
    {
      return 0.4;
    }
    tmp =  item == 'pincode_bg' ? '#f4f4f4' :  item == 'radio_size' ? "16px" : item=='bg' ||  item == "home_bg" || item == "suc_bg" ? "#fafcfc" : item == 'radio_bg' || item == 'radio_bg_selected' ? '#fff': '#fb6622';
  }
  return tmp;
}

const App = () => {
  const color_modes = ['button', 'radio_inner', 'radio_outer', 'radio_bg','d_btn','radio_bg_selected', 'accent', 'textfocus','bg', "home_bg", "suc_bg", "radio_size", "radio_size_left", "radio_size_top", "show_progress",'pincode_bg'];
  
  let str = '';
  for(let i = 0 ; i < color_modes.length;i++)
  {
    if(color_modes[i] =='d_btn')
    {
      
    str += "--color-mode-"+color_modes[i] +': '+ (adjustHexOpacity(getColorFromSt(color_modes[i])))+';';

    }else{
      str += "--color-mode-"+color_modes[i] +': '+ (getColorFromSt(color_modes[i]))+';';

    }
    
  }

  document.body.setAttribute(
    "style",
    str
  ); 

  useEffect(() => {
    CApp.addListener('appUrlOpen', (event) => {
      let url = event.url;
      if(url.indexOf('mhlez.com/resume/') > -1)
      {
        let slug = url.split('mhlez.com/resume/').pop();
        if (slug) {
          window.localStorage.setItem('resuming_item', 'yes');
          window.localStorage.setItem('resuming_item_id', slug);  
          window.location.href="/#withoutPin";
        }
      }else if(url.indexOf('mhlez.com/forceEmail') > -1)
      {
        let slug = url.split('mhlez.com/forceEmail/').pop();
        if (slug) {
          window.localStorage.setItem('forceEmail_address', slug);  
          window.location.href="/";
        }
      }
    });
  }, []);
  return (
    <IonApp>

		<AppProvider>
    <IonReactRouter>


      <Route path={ '/'} exact component={MainScreens} />
      <Route path={ '/inner'} exact component={Home} />
      <Route path={ '/inner/agent/:agent'} exact component={Home} />
      <Route path={ '/info'} exact component={Info} />
      <Route path={ '/mode/:mode/steps/:step/:plan'} exact component={Steps} />
      <Route path={ '/mode/:mode/steps/:step'} exact component={Steps} />
      <Route path={ '/submitted'} exact component={Submitted} />
      <Route path={ '/pendingSubmissions'} exact component={PendingSubmissions} />
      {/* <Route path={ '/menu'} exact component={Menu} /> */}
      <Route path={ '/colors'} exact component={Colors} />
      <Route path={ '/templates'} exact component={Templates} />
      <Route path={ '/add-template'} exact component={AddTemplate} />
      <Route path={ '/edit-template/:id'} exact component={EditTemplate} />
      <Route path={ '/settings'} exact component={Settings} />
      <Route path={ '/completedSubmissions'} exact component={CompletedSubmissions} />
      <Route path={ '/failedSubmissions'} exact component={FailedSubmissions} />
      <Route path={ '/products'} exact component={Products} />
      <Route path={ '/resume/:appId'} exact component={ResumeApplication} />
      <Route path={ '/forceEmail/:appId'} exact component={ForceEmail} />
    </IonReactRouter>
    </AppProvider>
    
  </IonApp>);
};

export default App;
