/**
 * External dependencies
 */
import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

class Button extends Component {
	render() {
		const { color,type, size, disabled, children, onClick , extraClass, noClass} = this.props;

		return (
			<button type={type||'button'}
			disabled={disabled? true: false}
				className={cx((noClass ? '' : 'btn ') + (extraClass ? extraClass : ' '),  {[`btn--${size}`]: size}, {[`btn--${color}`]: color})}
				onClick={onClick}
			>
				{ children }
			</button>
		);
	}
}

const ButtonLink = ({refs,  color, size, disabled, children, to, onClick }) => (
	<Link 
		to={to}
		ref={input => {   refs && refs( input);}}
		className={cx('btn', {[`btn--${size}`]: size}, {[`btn--${color}`]: color}, {[`disabled`]: disabled})}
		onClick={onClick}
	>
		{ children }
	</Link>
);

const ButtonIcon = ({ color, size, children, activeClass, onClick }) => (
	<button type="button"
	

		className={cx('btn btn--icon', {[`${activeClass}`]: activeClass}, {[`btn--${size}`]: size}, {[`btn--${color}`]: color})}
		onClick={onClick}
	>
		{ children }
	</button>
);

Button.Link = ButtonLink;
Button.Icon = ButtonIcon;

export default Button;