// const moment = require('moment');
// const states = [
// 	"AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "FM", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UM", "UT", "VT", "VA", "VI", "WA", "WV", "WI", "WY" ];

const steps = [
	{
	  "title": "Let’s start with the basics",
	  "subtitle": "We’ll use this info to Match the best plans available.",
	  "fields": [
		{
		  "isHalf": true,
		  "type": "text",
		  "id": "first_name",
		  "text": "First Name",
		  "isRequired": true
		},
		{
		  "isHalf": true,
		  "type": "text",
		  "id": "last_name",
		  "text": "Last Name",
			"class": "nomr",
		  "isRequired": true
		}
	  ],
	  "preradiolabel":"Gender",
	  "radios": [
		{
		  "name": "gender",
		  "isRequired": true,
		  
		  "extraClass": "  half_width_card  ",
		  "isdib": true,
		  "validationMessage": "Please select Gender",
		  
		  "values": [
			{
			  "id": "Male",
			  "text": "Male",
			  "standout": true,
			  "isChecked": false
			},
			{
			  "id": "Female",
			  "text": "Female",
			  "standout": true,
			  "isChecked": false
			}
		  ]
		}
	  ]
	},
	{
	  "title": "Applicant's Date of Birth",
		"isdobstep" : true,
	  "fields": [
		{
		  "title": "Date of Birth",
		  "icon": "ico-calendar",
		  "is_main_date_field": true,
		  "validationRule": "date",
		  "class": "flex_auto",
		  "isRequired":true,
		  "fields": [
			{
			  "isHalf": false,
			  "class": "tel",
			  "type": "text",
			  "id": "dob_month",
			  "text": "MM",
			  "isRequired": true,
			  "moveToNextAfter": 2,
			  "nextFieldId": "dob_date",
			  "maxLength": 2,
			  "pattern": "\\d*"
			},
			{
			  "isHalf": false,
			  "class": "tel",
			  "type": "text",
			  "id": "dob_date",
			  "text": "DD",
			  "isRequired": true,
			  "moveToNextAfter": 2,
			  "nextFieldId": "dob_year",
			  "maxLength": 2,
			  "pattern": "\\d*"
			},
			{
			  "isHalf": false,
			  "class": "tel",
			  "type": "text",
			  "id": "dob_year",
			  "text": "YYYY",
			  "isRequired": true,
			  "maxLength": 4,
			  "pattern": "\\d*"
			}
		  ]
		}
	  ]
	},
	{
	  "title": "Address",
	  "subtitle": "Great, <AGENT_NAME>, can I get the Applicants zip code and address.",
		
		 
	  "fields": [
		{
		  "is14th": true,
		  "type": "number",
		  "id": "zip",
		  "text": "Zipcode",
		  "isRequired": true,
		  "hideIfhasInternet": true,
		  "pattern": "\\d*"
		},
		{
		  "isHalf": true,
		  "type": "blank",
		  "id": "blank",
		  "text": "blank",
		  "isRequired": false
		},
		{
		  "isHalf": false,
		  "type": "geo",
		  "id": "address",
		  "text": "Street Address",
		  "text2": "APT/UNIT #",
		  "isRequired": true
		},
		{
		  "is34th": true,
		  "type": "text",
		  "id": "city",
		  "text": "City",
		  "isRequired": true
		},
		{
		  "is14th": true,
		  "type": "text",
		  "maxLength": 2,
		  "id": "state",
		  "text": "State",
		  "isRequired": false
		}
	  ],
	  hasClearOption:true
	},
	{
	  "title": "Employer",
	  "titleClass": "noMinHeight",
	  "showonlyon": "payroll",
	  "fields": [
		{
		  "type": "text",
		  "id": "employer",
		  "text": "Employer",
		  "isRequired": false
		},
		{
		  "type": "text",
		  "id": "groupNumber",
		  "text": "Group no.",
		  "fixedLabel": true,
		  "forcePlaceholder":"ex 721980",
		  "isHalf":true,
		  "isRequired": false
		}
	  ]
	},
	{
	  "title": "Coverage Options",
	  "messages": [
		{
		  "text": "Choose one to All Coverages."
		}
	  ],
	  "is_plan_step": true,
	  "showLoading": true,
	  "checkboxes": [
		{
		  "name": "radio-coverage",
		  "isRequired": true,
		  "validationMessage": "Radio is required",
		  "values": []
		}
	  ]
	},
	{
	  "title": "Who are we covering with the <PLAN> plan?",
	  "is_price_step": true,
	  "radios": [
		{
		  "name": "radio-insurance",
		  "isRequired": true,
		  "validationMessage": "Radio is required",
		  "values": [
			{
			  "id": "radio-insurance1",
			  "text": "Self",
			  "isChecked": false
			},
			{
			  "id": "radio-insurance2",
			  "text": "Self & Child(ren)",
			  "isChecked": false
			},
			{
			  "id": "radio-insurance3",
			  "text": "Self & Spouse",
			  "isChecked": false
			},	{
				id: 'radio-insurance5',
				text: 'Single Parent Family',
				isChecked: false,
			},
			{
				"id": "radio-insurance4",
				"text": "Family",
				"isChecked": false
			}
		  ]
		}
	  ],
	  "formHead": {
		"formHeadTitle": "Select Plan for <PLAN>",
		"formHeadSubtitle": "Cost per pay period {{radio_mode}}"
	  },
	  "radiosAlt": [
		{
		  "name": "radio_plan",
		  "isRequired": true,
		  "validationMessage": "Radio is required",
		  "values": []
		}
	  ],
	  "formTitle": "List insureds You want to cover:",
	  "dates": [
		{
		  "title": "Primary",
		  "isRequired": false,
		  "fieldName": {
			"id": "primary-full-name",
			"text": "Full Name",
			"hasLabel": false
		  },
		  "fieldDate": {
			"id": "primary-date",
			"label": "Date of Birth",
			"text": "DD/MM/YYYY"
		  },
		  "fieldGender": {
			"id": "gender",
			"label": "Gender",
		  },
		  "fieldH": {
			"id": "height-ftin",
			"label": "HT",
			"pattern": "\\d*"
		  },
		  "fieldW": {
			"id": "weight",
			"label": "Wt",
			"pattern": "\\d*"
		  },
		  "checkbox": {
			"id": "ch-primary-1"
		  }
		},
		{
		  "title": "Spouse",
		  "isRequired": false,
		  "fieldName": {
			"id": "spouse-full-name",
			"text": "Full Name",
			"hasLabel": false
		  },
		  "fieldGender": {
			"id": "spouse-gender",
			"label": "Gender",
		  },
		  "fieldDate": {
			"id": "spouse-date",
			"text": "DD/MM/YYYY"
		  },
		  "fieldH": {
			"id": "spouse-h",
			"label": "HT",
			"pattern": "\\d*"
		  },
		  "fieldW": {
			"id": "spouse-w",
			"label": "Wt",
			"pattern": "\\d*"
		  },
		  "checkbox": {
			"id": "ch-spouse"
		  }
		},
		{
		  "title": "Dependent",
		  "isRequired": false,
		  "fieldName": {
			"id": "dependent1-full-name",
			"text": "Full Name",
			"hasLabel": false
		  },
		  "fieldGender": {
			"id": "dependent1-gender",
			"label": "Gender",
		  },
		  "fieldDate": {
			"id": "dependent1-date",
			"text": "DD/MM/YYYY"
		  },
		  "fieldH": {
			"id": "dependent1-h",
			"label": "HT",
			"pattern": "\\d*"
		  },
		  "fieldW": {
			"id": "dependent1-w",
			"label": "Wt",
			"pattern": "\\d*"
		  },
		  "checkbox": {
			"id": "ch-dependent1"
		  }
		},
		{
		  "title": "Dependent",
		  "isRequired": false,
		  "fieldName": {
			"id": "dependent2-full-name",
			"text": "Full Name",
			"hasLabel": false
		  },
		  "fieldGender": {
			"id": "dependent2-gender",
			"label": "Gender",
		  },
		  "fieldH": {
			"id": "dependent2-h",
			"label": "HT",
			"pattern": "\\d*"
		  },
		  "fieldW": {
			"id": "dependent2-w",
			"label": "Wt",
			"pattern": "\\d*"
		  },
		  "fieldDate": {
			"id": "dependent2-date",
			"text": "DD/MM/YYYY"
		  },
		  "checkbox": {
			"id": "ch-dependent2"
		  }
		} 
	  ]
	},
	{
	  "title": "Tell us your approx. Height and weight",
	  "is_height_field": true,
	  "subtitle": "",
	  "fields": [
		{
		  "isHalf": true,
		  "type": "text",
		  "id": "height-ft",
		  "text": "Height-Ft",
		  "pattern": "\\d*",
		  "isRequired": true
		},
		{
		  "isHalf": true,
		  "type": "text",
		  "id": "height-in",
		  "pattern": "\\d*",
		  "text": "In",
		  "class": "nomr",
		  "isRequired": true
		},
		{
		  "isHalf": false,
		  "type": "text",
		  "pattern": "\\d*",
		  "id": "weight",
		  "text": "Weight(Lbs)",
		  "isRequired": true
		}
	  ]
	},
	{
	  "title": "Beneficiary",
	  "subtitle": "Please list Beneficiaries below.",
	  "is_before_question_step": true,
	  "has_benef_repeater": true,
	  "fields": [
		{
		  "type": "benef-repeater",
		  "validationRule": "benef-repeater",
		  "id": "beneficiary_repeater",
		  "text": "Beneficiary",
		  "noLabel": true,
		  "isRequired": true
		}
	  ]
	},
	{
	  "is_question_step": true,
	  "title": "Loading questions...",
	  "subtitle": "please wait while the questions are getting loaded",
	  "do_not_proceed": true
	},
	{
	  "title": "Payment",
	  "showonlyon": "individual",
	  "is_payroll_auth_alt": true,
	  "titleClass": "noMinHeight",
	  "radioACH": {
		"name": "radio-ach",
		"id": "radio-ach1",
		"text": "hrtr",
		"isChecked": false
	  },
	  "is_billing_screen":true,
	  "fieldsClass" : " radio_card ",
	  "fields": [
			{
				"isHalf": true,
				"isSmall": true,
				"type": "text",
				"id": "routing-number",
				"text": "Routing no.",
				"pattern": "\\d*",
				"class": "nomr",
				"isRequired": false,
				"class":" nomarginRight withBorderRight nomr "
	
			},
		{
		  "isHalf": true,
		  "isSmall": true,
		  "type": "text",
		  "id": "account-number",
		  "text": "Account no.",
		  "pattern": "\\d*",
			"class":" nomarginLeft withBorderLeft nomr ",
		  "isRequired": false
		},

		{
		  "isHalf": false,
		  "isSmall": true,
		  "type": "text",
		  "id": "name-on-account",
		  "text": "Name on Account",
		  "isRequired": false
		},
		{
		  "isHalf": false,
		  "isSmall": false,
		  "type": "select",
		  "options": [
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"11",
			"12",
			"13",
			"14",
			"15",
			"16",
			"17",
			"18",
			"19",
			"20",
			"21",
			"22",
			"23",
			"24",
			"25",
			"26",
			"27",
			"28"
		  ],
		  "id": "desired-withdrawal-date",
		  "text": "Desired Withdrawal Date",
		  "isRequired": false,
		  "pattern": "\\d*"
		},
		{
		  "isHalf": false,
		  "type": "datepicker",
		  "id": "requested-effective-date",
		  "text": "Requested Effective Date",
		  "isRequired": false,
		  "fixedLabel": true,
		  "max": "2022-10-29",
		  "min": "2022-08-31",
		  "lesspsacebelow": true
		},
		
	  ],
	  "radiosTop": [
		{
		  "name": "paymentTypeId",
		  "isRequired": false,
		  "label": "Payment Type",
		  "hasAmount2":true,
		  "validationMessage": "Radio is required",
		  "values": [
			{
			  "id": "140",
			  "text": "EFT - Monthly",
			  "value": "140",
			  "multipler": 1,
			  "isChecked": false
			},
			{
			  "id": "142",
			  "text": "Direct Billing",
			  "value": "142",
			  "isChecked": false
			}
		  ]
		},
		{
		  "name": "schedule",
		  "isRequired": true,
		  "hasAmount": true,
		  "is_bill_box": true,
		  "label": "ACH – Automated Bank Account withdrawal",
		  "label_alt": "Direct BIll sent to:",
		  "validationMessage": "Radio is required",
		  "values": [
			{
			  "id": "annual",
			  "text": "Annual",
			  "multipler": 12,
			  "isChecked": false
			},
			{
			  "id": "semi-annual",
			  "text": "Semi Annual",
			  "multipler": 6,
			  "isChecked": false
			},
			{
			  "id": "quarterly",
			  "text": "Quarterly",
			  "multipler": 3,
			  "isChecked": false
			},
			{
			  "id": "monthly",
			  "multipler": 1,
			  "text": "Monthly",
			  "isChecked": false
			}
		  ]
		},
		
	  ],
	  "radios": [
		{
		  "name": "type-of-account",
		  "isRequired": false,
		  "validationMessage": "Please select Account Type",
		  "label": "Account Type",
		  "values": [
			{
			  "id": "radio-coverage1",
			  "text": "Savings Account",
			  "standout": true,
			  "isChecked": true
			},
			{
			  "id": "radio-coverage2",
			  "text": "Checking Account",
			  "standout": true,
			  "isChecked": false
			}
		  ]
		}
	  ],
	  logo: require('../assets/svg/logo-secure.svg').default,
	},
	{
	  "title": "Payroll Deduction Authorization",
	  "showonlyon": "payroll",
	  "is_payroll_auth": true,
	  "subtitle": "I hereby authorize my Employer to deduct from my salary and pay to ManhattanLife Insurance Company of America the monthly deposits for policies applied for.",
	  "radios": [
		{
		  "name": "radio-payroll",
		  "isRequired": true,
		  "validationMessage": "Radio is required",
		  "values": [
			{
			  "id": "radio-Payroll1",
			  "autoProceed": true,
			  "text": "Yes",
			  "hint": "(I, approve payroll deductions)",
			  "isChecked": false
			},
			{
			  "id": "radio-Payroll2",
			  "text": "No",
			  "isChecked": false
			}
		  ]
		}
	  ],
	  "textarea": {
		"isRequired": true,
		"visibleIfCondition": "No",
		"visible_if": "radio-payroll",
		"id": "message-Payroll1",
		"text": "Please Enter Details Here…"
	  }
	},
	{
	  "title": "Social Security Number",
	  "is_ssn_step": true,
	  "subtitleBold": "Please enter social security no. below",
		"subTitleClass": "gap-below",
	  "subtitle": "At ManhattanLife, social security no. and privacy is our top priority. We use this information to validate Your identity, prevent fraudulent behavior, & expedite your claims.",
	//   "radios": [
	// 	{
	// 	  "name": "spouse_gender",
	// 	  "className": "topsmall dibradio",
	// 	  "isRequired": false,
	// 	  "isHalfHalf": true,
	// 	  "extraClass": "plr nocard",
	// 	  "isdib": true,
	// 	  "validationMessage": "Please select Gender",
	// 	  "label": "Spouse Gender",
	// 	  "values": [
	// 		{
	// 		  "id": "Male",
	// 		  "text": "Male",
	// 		  "standout": true,
	// 		  "isChecked": false
	// 		},
	// 		{
	// 		  "id": "Female",
	// 		  "text": "Female",
	// 		  "standout": true,
	// 		  "isChecked": false
	// 		}
	// 	  ]
	// 	}
	//   ],
	  "fieldCard": {
		"id": "card-number",
		"placeholder": "XXX-XX-XXXX",
		"mask": "999-99-9999",
		icon: require('../assets/svg/ico-card.svg').default,
		"isRequired": true,
		"pattern": "\\d*",
		"validationRule": "creditCard"
	  },
	  "fieldCard2": {
		"id": "spouse-ssn",
		"placeholder": "XXX-XX-XXXX",
		"mask": "999-99-9999",
		icon: require('../assets/svg/ico-card.svg').default,
		"isRequired": true,
		"pattern": "\\d*",
		"validationRule": "creditCard"
	  }
	},
	{
	  "title": "Contact information",
	  "subtitle": "No Solicitations, only used to verify information or expedite your claim.",
	
	  "contact": {
		"phone": {
			icon: require('../assets/svg/ico-tel.svg').default,
		},
		"email": {
			icon: require('../assets/svg/ico-mail.svg').default,
		  "field": {
			"id": "contact-email",
			"text": "Email"
		  }
		},
		
	  },
	  "hint2":[ "Manhattan life will not use contact information for solicitation. This information is only used to verify application information or assist in expediting your claim."]
	},
	{
	  "is_maiden_step": true,
	  "class": 'maiden_step',
	  "title": "Complete application",
	
	  "fields": [
		{
		  "isHalf": false,
		  "type": "text",
		  "id": "mothers-maiden-name",
		  "text": "Mother's Maiden Name",
		  "isRequired": true
		}
	  ],
	  "showMessage": true
	},
	{
	  "is_agent_step": true,
	  "titleClass": "noMinHeight lessfontsize",
	  "subTitleClass": "lessfontsize",
	  "title": "Agent Info",
	
	  "fields": [
		{
		  "isHalf": false,
		  "type": "datepicker",
		  "id": "requested-effective-date",
		  "text": "Requested Effective Date",
		  "isRequired": false,
		  "fixedLabel": true,
		  "max": "2022-10-29",
		  "min": "2022-08-31",
		  "lesspsacebelow": true
		},
		{
		  "isHalf": false,
		  "type": "text",
		  "id": "agent-no",
		  "text": "Agent No.",
		  "isRequired": false,
		  "fixedLabel": true,
		  "lesspsacebelow": true
		},
		{
		  "isHalf": false,
		  "type": "text",
		  "id": "agent-name",
		  "text": "Printed Agent Name",
		  "isRequired": false,
		  "fixedLabel": true,
		  "lesspsacebelow": true
		},
		{
		  "isHalf": false,
		  "type": "date",
		  "id": "agent-signature-date",
		  "text": "Date",
		  "isRequired": false,
		  "fixedLabel": true,
		  "lesspsacebelow": true
		},
 		{
			"type": "split-repeater",
			"validationRule": "split-repeater",
			"id": "split_repeater",
			"text": "Agent Split if applicable",
			"noLabel": true,
			"isRequired": true
		  }
		 
	  ],
	  "radio_class": "mt-20 smaller_checkbox",
	//   "checkboxes": [
	// 	{
	// 	  "name": "radio-default-agent",
	// 	  "isRequired": true,
	// 	  "trueFalseMode": true,
	// 	  "validationMessage": "Radio is required",
	// 	  "values": [
	// 		{
	// 		  "id": "default-agent",
	// 		  "text": "Save as Agent Temp.",
	// 		  "standout": true,
	// 		  "trueFalseMode": true,
	// 		  "subtext": " ",
	// 		  "isChecked": false
	// 		}
	// 	  ]
	// 	}
	//   ],
	//   textareaText: "Template wil be saved when the application is submitted.",
	//   "textarea": {
	// 	"id": "default_agent_name",
	// 	"isRequired": true,
	// 	"type": "text",
	// 	"className": "mt-20 mrlr",
	// 	"visibleIfCondition": true,
	// 	"fixedLabel": true,
	// 	"visible_if": "default-agent",
	// 	"text": "Give it a name"
	//   },
	  "showMessage": false
	}
  ];
  export default steps;
  