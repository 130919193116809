/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import jquery from "jquery";

import Wrapper from "../layouts/wrapper";
import Section from "../layouts/section";
import { SketchPicker } from "@bahouse/react-color";
import Field from "../components/field/field";
import Button from "../components/button/button";
import Radio from "../components/radio/radio";
import RadioForProducts from "../components/radio/radio-for-products";
import { GoBack } from "../components/GoBack";
let $ = jquery;

const adjustHexOpacity = (opacity) => {
  console.log(opacity);
  // let rgba = {
  //   r: 153,g: 153,b: 153, a: opacity
  //  }
  let rgba = {
    r: 85,
    g: 85,
    b: 85,
    a: opacity,
  };
  let bg = {
    r: 250,
    g: 252,
    b: 252,
  };
  const red = Math.round((1 - rgba.a) * bg.r + rgba.a * rgba.r);
  const green = Math.round((1 - rgba.a) * bg.g + rgba.a * rgba.g);
  const blue = Math.round((1 - rgba.a) * bg.b + rgba.a * rgba.b);

  return "rgb(" + red + ", " + green + ", " + blue + ")";
};

const styles = {
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
    // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
  popover: {
    position: "absolute",
    zIndex: "2",
    right: 0,
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
};

const color_modes = [
  "button",
  "radio_inner",
  "radio_outer",
  "radio_bg",
  "radio_bg_selected",
  "accent",
  "textfocus",
  "bg",
  "home_bg",
  "suc_bg",
  // 'pincode_bg',
  "radio_size",
  "radio_size_top",
  "radio_size_left",
  "show_progress",
  "d_btn",
  "emcpr_agent",
  "emcpr_applicant",
  "pincode_layout",
  "show_pay_mode",
  'sounds',
];
const labels = {
  button: "Button Color",
  radio_inner: "Radio Inner dial Color",
  radio_outer: "Radio Outer dial Color",
  radio_bg: "Radio Background",
  radio_bg_selected: "Selected Radio Background",
  accent: "Accent Color",
  textfocus: "Input Field Focus Outline",
  bg: "Page Background",
  home_bg: "Homepage Background",
  suc_bg: "Last Page Background",
  radio_size: "Radio Size",
  show_progress: "Show Progress bar",
  d_btn: "Disabled Button Background",
  pincode_bg: "Pincode screen Background",
  pincode_layout: "Pincode screen layout",
};
class Colors extends Component {
  state = {
    data: [],
    loaded: false,
    yet_to_load: true,
  };

  componentDidMount() {
    let colors = {
      colorPalette: [],
    };
    
    for (let i = 0; i < color_modes.length; i++) {
      colors["color_mode_" + color_modes[i]] = localStorage.getItem(
        "color_mode_" + color_modes[i]
      );
      if (!colors["color_mode_" + color_modes[i]]) {
        if (color_modes[i] == "show_progress") {
          colors["color_mode_" + "show_progress"] = "visible";
        } else if (color_modes[i] == "pincode_bg") {
          colors["color_mode_" + color_modes[i]] = "#f4f4f4"; // to change
        } else if (color_modes[i] == "emcpr_agent") {
          colors["color_mode_" + color_modes[i]] = "checked";
        }else if (color_modes[i] == "sounds") {
          colors["color_mode_" + color_modes[i]] = "no";
        }
         else if (color_modes[i] == "emcpr_applicant") {
          colors["color_mode_" + color_modes[i]] = "unchecked";
        } else if (color_modes[i] == "pincode_layout") {
          colors["color_mode_" + color_modes[i]] = "1";
        } else if (color_modes[i] == "show_pay_mode") {
          colors["color_mode_" + color_modes[i]] = "yes";
        } else if (
          color_modes[i] == "bg" ||
          color_modes[i] == "home_bg" ||
          color_modes[i] == "suc_bg"
        ) {
          colors["color_mode_" + color_modes[i]] = "#fafcfc";
        } else if (color_modes[i] == "radio_size") {
          colors["color_mode_" + color_modes[i]] = "16px";
        } else if (color_modes[i] == "d_btn") {
          colors["color_mode_" + color_modes[i]] = 0.4;
        } else if (color_modes[i] == "radio_size_top") {
          colors["color_mode_" + color_modes[i]] = "17px";
        } else if (color_modes[i] == "radio_size_left") {
          colors["color_mode_" + color_modes[i]] = "14px";
        } else if (
          color_modes[i] == "radio_bg" ||
          color_modes[i] == "radio_bg_selected"
        ) {
          colors["color_mode_" + color_modes[i]] = "#fff";
        } else {
          colors["color_mode_" + color_modes[i]] = "#fb6622";
        }
      }
    }
    // console.log(colors);
    this.setState(colors, this.givePresets);
  }

  givePresets() {
    let obj = [
      "#D0021B",
      "#F5A623",
      "#F8E71C",
      "#8B572A",
      "#1B572A",
      "#7ED321",
      "#417505",
      "#BD10E0",
      "#9013FE",
      "#4A90E2",
      "#50E3C2",
      "#B8E986",
      "#000000",
      "#4A4A4A",
      "#9B9B9B",
      "#FFFFFF",
    ];
    for (let i = 0; i < color_modes.length; i++) {
      obj.push({
        color: this.state["color_mode_" + color_modes[i]],
        title: i + 1,
      });
    }
    this.setState({
      pallete: obj,
    });
  }

  changeColor(item, i) {
    console.log(item, i);
    this.setState(
      {
        ["color_mode_" + item]: i.hex,
      },
      () => {
        localStorage.setItem("color_mode_" + item, i.hex);

        let str = "";
        for (let i = 0; i < color_modes.length; i++) {
          if (color_modes[i] == "d_btn") {
            str +=
              "--color-mode-" +
              color_modes[i] +
              ": " +
              adjustHexOpacity(this.state["color_mode_" + color_modes[i]]) +
              ";";
          } else {
            str +=
              "--color-mode-" +
              color_modes[i] +
              ": " +
              this.state["color_mode_" + color_modes[i]] +
              ";";
          }
        }

        document.body.setAttribute("style", str);
        this.givePresets();
      }
    );
  }

  doDemo(item, id) {
    if (item == "button") {
      return <Button>Demo Button</Button>;
    }
    if (item == "d_btn") {
      return (
        <Button disabled extraClass="disabled">
          Demo Button
        </Button>
      );
    }
    if (item == "radio_inner") {
      return (
        <div className="radios " style={{ width: "95%", marginRight: "5%" }}>
          <ul
            className="radio_card radio_card_1"
            style={{ padding: "16px !important", width: "100%" }}
          >
            <li>
              <Radio
                className=""
                onChange={(a, b) => {}}
                defaultChecked={true}
                id={id || "demo"}
                name={id || "demo"}
                value={"Demo 1"}
                text="Demo 1"
              />
            </li>
            <li>
              <Radio
                className=""
                onChange={(a, b) => {}}
                defaultChecked={false}
                id={id || "demo"}
                name={id || "demo"}
                value={"Demo 2"}
                text="Demo 2"
              />
            </li>
          </ul>
        </div>
      );
    }
    if (item == "textfocus") {
      return (
        <div className={"form"}>
          {" "}
          <div className={"form__row"}>
            <div className="form__col">
              <Field upperclass="focused" fixedLabel label="Demo" />
            </div>
          </div>
        </div>
      );
    }

    if (item == "accent") {
      return (
        <span style={{ color: "var(--color-mode-accent)" }}>
          <span className="fa fa-arrow-left"></span>{" "}
          <span className="fa fa-arrow-right"></span>{" "}
          <span className="fa fa-chevron-left"></span>{" "}
          <span className="fa fa-chevron-right"></span>
        </span>
      );
    }
    return false;
  }
  render() {
    return (
      <Wrapper>
        <div className="header_main">
          <div className="shell" style={{ paddingLeft: 5, paddingRight: 5 }}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  alt="logo"
                  src={require("../assets/images/logo_top.png").default}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "var(--color-mode-bg)" }}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{ position: "relative" }}>
                <h1 className="text-center" style={{ minHeight: "auto" }}>
                  Colors and Settings
                </h1>

                <GoBack />
              </div>
            </Section.Head>
            <Section.Body>
              <div
                style={{ margin: 8, background: "none" }}
                className="colors_screen"
              >
                {color_modes.map((item, i) => {
                  if (
                    item == "radio_size" ||
                    item == "d_btn" ||
                    item == "radio_size_top" ||
                    item == "radio_size_left" ||
                    item == "show_progress" ||
                    item == "emcpr_agent" ||
                    item == "pincode_layout" ||
                    item == "show_pay_mode" ||
                    item == "sounds" ||
                    
                    item == "emcpr_applicant"
                  )
                    return false;
                  return (
                    <div className="color_selection_box" key={item}>
                      <div className="color_selection_top">
                        <div style={{ flex: 1 }}>
                          <label>
                            <span className="colorNumber">{i + 1}</span>{" "}
                            {labels[item]}:
                          </label>
                          <div
                            className="color_demo"
                            style={{
                              zoom: 0.8,
                              flex: 1,
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {this.doDemo(item)}
                          </div>
                        </div>
                        <div className="color_selection_top_right">
                          <button
                            onClick={(e) => {
                              $([
                                document.documentElement,
                                document.body,
                              ]).animate(
                                {
                                  scrollTop: $(e.target).offset().top - 100,
                                },
                                1
                              );
                              this.setState({
                                ["showingColorPicker_" + item]: true,
                              });
                              setTimeout(function () {
                                $("body").css({ overflow: "hidden" });
                              }, 100);
                            }}
                            className="color_btn"
                            style={{
                              background: this.state["color_mode_" + item],
                            }}
                          ></button>
                          {this.state["showingColorPicker_" + item] ? (
                            <div style={styles.popover}>
                              <div
                                style={styles.cover}
                                onClick={(e) => {
                                  $("body").css({ overflow: "auto" });
                                  this.setState({
                                    ["showingColorPicker_" + item]: false,
                                  });
                                }}
                              />
                              <div>
                                <div className="colorPreview">
                                  <div
                                    style={{
                                      backgroundColor:
                                        this.state["color_mode_" + item],
                                    }}
                                  >
                                    <div>Preview</div>
                                  </div>
                                </div>
                                <SketchPicker
                                  presetColors={this.state.pallete}
                                  disableAlpha
                                  color={this.state["color_mode_" + item]}
                                  onChange={(e) => {
                                    this.changeColor(item, e);
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="color_selection_box">
                  <div className="color_selection_top">
                    <div style={{ flex: 1 }}>
                      <label>
                        <span className="colorNumber">{11}</span>{" "}
                        {"Pincode screen Layout"}:
                      </label>

                      <div
                        className="color_demo"
                        style={{
                          zoom: 0.8,
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="radios "
                          style={{ width: "95%", marginRight: "5%" }}
                        >
                          <ul
                            className="radio_card radio_card_1"
                            style={{
                              padding: "16px !important",
                              width: "100%",
                            }}
                          >
                            <li>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"pincode_layout"}
                                  id={"pincode_layout"}
                                  value={"1"}
                                  checked={
                                    this.state.color_mode_pincode_layout == "1"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("pincode_layout", {
                                      hex: 1,
                                    });
                                  }}
                                />
                                <label htmlFor={"pincode_layout"}>
                                  {"Layout 1"}
                                </label>
                              </div>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"pincode_layout"}
                                  id={"pincode_layout2"}
                                  value={"2"}
                                  checked={
                                    this.state.color_mode_pincode_layout == "2"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("pincode_layout", {
                                      hex: "2",
                                    });
                                  }}
                                />
                                <label htmlFor={"pincode_layout2"}>
                                  {"Layout 2"}
                                </label>
                              </div>

                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"pincode_layout"}
                                  id={"pincode_layout3"}
                                  value={"3"}
                                  checked={
                                    this.state.color_mode_pincode_layout == "3"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("pincode_layout", {
                                      hex: 3,
                                    });
                                  }}
                                />
                                <label htmlFor={"pincode_layout3"}>
                                  {"Layout 3"}
                                </label>
                              </div>

                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"pincode_layout"}
                                  id={"pincode_layout4"}
                                  value={"4"}
                                  checked={
                                    this.state.color_mode_pincode_layout == "4"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("pincode_layout", {
                                      hex: 4,
                                    });
                                  }}
                                />
                                <label htmlFor={"pincode_layout4"}>
                                  {"Layout 4"}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="color_selection_top_right"></div>
                  </div>
                </div>

                <div className="color_selection_box">
                  <div className="color_selection_top">
                    <div style={{ flex: 1 }}>
                      <label>
                        <span className="colorNumber">{12}</span>{" "}
                        {"Show/hide Progressbar"}:
                      </label>

                      <div
                        className="color_demo"
                        style={{
                          zoom: 0.8,
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="radios "
                          style={{ width: "95%", marginRight: "5%" }}
                        >
                          <ul
                            className="radio_card radio_card_1"
                            style={{
                              padding: "16px !important",
                              width: "100%",
                            }}
                          >
                            <li>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"show_progress"}
                                  id={"show_progress"}
                                  value={"visible"}
                                  checked={
                                    this.state.color_mode_show_progress ==
                                    "visible"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("show_progress", {
                                      hex:
                                        this.state.color_mode_show_progress ==
                                        "visible"
                                          ? "hidden"
                                          : "visible",
                                    });
                                  }}
                                />
                                <label htmlFor={"show_progress"}>
                                  {"Visible"}
                                </label>
                              </div>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"show_progress_1"}
                                  id={"show_progress_1"}
                                  value={"hidden"}
                                  checked={
                                    this.state.color_mode_show_progress ==
                                    "visible"
                                      ? false
                                      : true
                                  }
                                  onChange={() => {
                                    this.changeColor("show_progress", {
                                      hex:
                                        this.state.color_mode_show_progress ==
                                        "visible"
                                          ? "hidden"
                                          : "visible",
                                    });
                                  }}
                                />
                                <label htmlFor={"show_progress"}>Hidden</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="color_selection_top_right"></div>
                  </div>
                </div>
                <div className="color_selection_box">
                  <div className="color_selection_top">
                    <div style={{ flex: 1 }}>
                      <label>
                        <span className="colorNumber">{13}</span>{" "}
                        {"Radio inner dial size"}:
                      </label>
                      <div
                        className="color_demo"
                        style={{
                          zoom: 0.8,
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {this.doDemo("radio_inner", "ff")}
                      </div>
                      <input
                        type="range"
                        min="5"
                        max="24"
                        onChange={(e) => {
                          this.changeColor("radio_size", {
                            hex: "" + e.target.value + "px",
                          });
                          this.changeColor("radio_size_top", {
                            hex: "" + (50 - e.target.value) / 2 + "px",
                          });
                          this.changeColor("radio_size_left", {
                            hex: "" + ((24 - e.target.value) / 2 + 10) + "px",
                          });
                        }}
                        value={(
                          this.state["color_mode_" + "radio_size"] || ""
                        ).replace("px", "")}
                      />
                    </div>
                  </div>
                </div>

                <div className="color_selection_box">
                  <div className="color_selection_top">
                    <div style={{ flex: 1 }}>
                      <label>
                        <span className="colorNumber">{14}</span>{" "}
                        {labels["d_btn"]}:
                      </label>
                      <div
                        className="color_demo"
                        style={{
                          zoom: 0.8,
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {this.doDemo("d_btn", "ff")}
                      </div>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        onChange={(e) => {
                          this.changeColor("d_btn", {
                            hex: e.target.value,
                          });
                        }}
                        step="0.01"
                        value={this.state["color_mode_" + "d_btn"] || 0.4}
                      />
                    </div>
                  </div>
                </div>

                <div className="color_selection_box">
                  <div className="color_selection_top">
                    <div style={{ flex: 1 }}>
                      <label>
                        <span className="colorNumber">{15}</span>{" "}
                        {"Email copy of application"}:
                      </label>
                    </div>
                    <div className="color_selection_top_right"></div>
                  </div>
                </div>

                <div
                  className="  width-100per is_ls_mail_option  small_btn btn--border btn btn--small"
                  style={{ fontSize: 12 }}
                >
                  <div>E-mail copy of Application To</div>
                  <div className="is_small_radio">
                    <RadioForProducts
                      trueFalseMode
                      type="checkbox"
                      defaultChecked={
                        this.state.color_mode_emcpr_agent == "checked"
                        // this.state.color_mode_emcpr_agent ==
                        // 'checked'
                        //   ? true
                        //   : false
                      }
                      name="emcpr_agent"
                      id="emcpr_agent"
                      text="Agent"
                      trueRepresentative="yes"
                      onChange={() => {
                        this.changeColor("emcpr_agent", {
                          hex:
                            this.state.color_mode_emcpr_agent == "checked"
                              ? "unchecked"
                              : "checked",
                        });
                      }}
                    />
                  </div>
                  <div className="is_small_radio">
                    <RadioForProducts
                      trueFalseMode
                      type="checkbox"
                      defaultChecked={
                        this.state.color_mode_emcpr_applicant == "checked"
                          ? true
                          : false
                        // this.context.stepValues["mail_to_customer"]
                      }
                      name="emcpr_applicant"
                      id="emcpr_applicant"
                      text="Applicant"
                      trueRepresentative="yes"
                      onChange={() => {
                        this.changeColor("emcpr_applicant", {
                          hex:
                            this.state.color_mode_emcpr_applicant == "checked"
                              ? "unchecked"
                              : "checked",
                        });
                      }}
                    />
                  </div>
                </div>


                <div className="color_selection_box">
                  <div className="color_selection_top">
                    <div style={{ flex: 1 }}>
                      <label>
                        <span className="colorNumber">{16}</span>{" "}
                        {"Show Pay Mode"}:
                      </label>

                      <div
                        className="color_demo"
                        style={{
                          zoom: 0.8,
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="radios "
                          style={{ width: "95%", marginRight: "5%" }}
                        >
                          <ul
                            className="radio_card radio_card_1"
                            style={{
                              padding: "16px !important",
                              width: "100%",
                            }}
                          >
                            <li>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"show_pay_mode"}
                                  id={"show_pay_mode"}
                                  value={"yes"}
                                  checked={
                                    this.state.color_mode_show_pay_mode == "yes"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("show_pay_mode", {
                                      hex: 'yes',
                                    });
                                  }}
                                />
                                <label htmlFor={"show_pay_mode"}>
                                  {"Yes"}
                                </label>
                              </div>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"show_pay_mode"}
                                  id={"show_pay_mode2"}
                                  value={"no"}
                                  checked={
                                    this.state.color_mode_show_pay_mode == "no"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("show_pay_mode", {
                                      hex: "no",
                                    });
                                  }}
                                />
                                <label htmlFor={"show_pay_mode2"}>
                                  {"No"}
                                </label>
                              </div>
 

                             
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="color_selection_top_right"></div>
                  </div>
                </div>


                <div className="color_selection_box">
                  <div className="color_selection_top">
                    <div style={{ flex: 1 }}>
                      <label>
                        <span className="colorNumber">{17}</span>{" "}
                        {"Pin Code Sounds"}:
                      </label>

                      <div
                        className="color_demo"
                        style={{
                          zoom: 0.8,
                          flex: 1,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="radios "
                          style={{ width: "95%", marginRight: "5%" }}
                        >
                          <ul
                            className="radio_card radio_card_1"
                            style={{
                              padding: "16px !important",
                              width: "100%",
                            }}
                          >
                            <li>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"sounds"}
                                  id={"sounds"}
                                  value={"yes"}
                                  checked={
                                    this.state.color_mode_sounds == "yes"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("sounds", {
                                      hex: 'yes',
                                    });
                                  }}
                                />
                                <label htmlFor={"sounds"}>
                                  {"Yes"}
                                </label>
                              </div>
                              <div className={"radio"}>
                                <input
                                  type={"checkbox"}
                                  name={"sounds"}
                                  id={"sounds2"}
                                  value={"no"}
                                  checked={
                                    this.state.color_mode_sounds == "no"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.changeColor("sounds", {
                                      hex: "no",
                                    });
                                  }}
                                />
                                <label htmlFor={"sounds2"}>
                                  {"No"}
                                </label>
                              </div>
 

                             
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="color_selection_top_right"></div>
                  </div>
                </div>

                {/* end of options */}
              </div>
            </Section.Body>
            <br />
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <a
                style={{ textDecoration: "none", color: "#727272" }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();

                  window
                    .swal(
                      "RESET SETTINGS",
                      "Are you sure you want to reset styles and settings to default?",
                      {
                        buttons: ["Cancel", "Reset"],
                      }
                    )
                    .then((value) => {
                      console.log(value);
                      if (value) {
                        color_modes.map((item, i) => {
                          window.localStorage.removeItem("color_mode_" + item);
                        });
                        window.location.reload();
                      }
                    });
                }}
              >
                <i className="fa fa-retweet" aria-hidden="true"></i> Reset to
                Default
              </a>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Section.Foot></Section.Foot>
          </Section>
        </div>
      </Wrapper>
    );
  }
}

export default Colors;
