
import rates from './data/cached_rates/index.js';

let PRICES = {
    138 : rates.accident.individual,
    '138_payroll' : rates.accident.payroll,
    9: rates.ccp.individual ,
    '9_payroll' : rates.ccp.payroll,

    '9_fl': rates.ccpfl.individual ,
    '9_fl_payroll' : rates.ccpfl.payroll,


    301 : rates.dvh.individual,
    '301_payroll' : rates.dvh.payroll,
    // 300 : rates.hhc.individual,
    // '300_payroll' : rates.hhc.payroll
}

const getCachedRates = function(data, product_id, price_mode, self_mode)
{

    console.log(data);
    console.log('-------------------')

    let state = data.Quote.StateCode;
    state = (state||'').toUpperCase();
    let mode = 'self';

    if(self_mode === 'Family')
    {
        self_mode = 'family';
        mode = 'family';
    }

    if(self_mode === 'Self & Child')
    {
        self_mode = 'self_child';
        mode = 'self_child';
    }

    // console.log(self_mode)

    if(self_mode === 'Self & Spouse')
    {
        self_mode = 'self_spouse';
        mode = 'self_spouse';
    }
    if(self_mode === 'Single Parent Family')
    {
        self_mode = 'family';
        mode = 'family';
    }
    // console.log(self_mode, mode);
    let slabs = [
        [18,44,   1991,],
        [45,54,     1972,],
        [55,64,     1962,],
        [65,69,     1954,],
    ];

    let ccl_fl_slabs = [
        [18,29,     1998,],
        [30,39,     1987,],
        [40,49,     1977,],
        [50,59,     1967,],
        [60,69,     1957,],
    ]

    let dvh_slabs = [
        [3,  7,     2012,],
        [18,39,     1991,],
        [40,54,     1972,],
        [55,64,     1962,],
        [65,74,     1952,],
        [75,84,     1942,],
        [85,99,     1937,],
    ];
 
    let add_money_for_extra = false;
    if(product_id==='301'||product_id===301||product_id==='301_payroll')
    {
        add_money_for_extra = true;
        slabs = dvh_slabs;
    }

    if(product_id == 9 || product_id == '9')
    {
        if(state == 'FL'  || state == 'fl')
        {
            product_id = '' + product_id + '_fl';
            slabs = ccl_fl_slabs
        }
    }

    if(price_mode === 'payroll')
    {
        product_id = ''+product_id+'_payroll'
    }
    let rates  = PRICES[product_id] ? PRICES[product_id]  : {};

    if(!data){
        return [];
    }

    let add_extra = 0;
    // let add_extra_group = "2012";
    // console.log(data.Dependents);
    if(!data.Dependents || data.Dependents.length === 0)
    {
        mode = 'self';
    }else{
        if(  self_mode === 'family' && data.Dependents.length > 4){
            add_extra = data.Dependents.length - 4;
        }else if(  self_mode === 'self_child' && data.Dependents.length > 3 ){
            add_extra = data.Dependents.length - 3;
        }
    }

    if(add_money_for_extra)
    {
        //
    }else{
        add_extra = 0;
    }

    console.log('add_extra', add_extra, mode)
    let rates_mode = rates[mode];

    if(!rates_mode)
    {
        rates_mode = {};
    }
    
    
    let BirthDate = data.Quote.BirthDate;
    let plans = [];
    let riders = [];
    data.SelectedPlans.map((item)=>{plans.push(item.PlanUnitId)});
    data.selectedRiders.map((item)=>{riders.push(item.riderUnitId)});
    
    let age = parseInt((new Date()).getFullYear()) - parseInt(BirthDate);
    // console.log(age);
    let avg_year_1 = 0;
    slabs.map((item)=>{
        if(age >= item[0] && age <= item[1])
        {
            avg_year_1 = item[2];
        }
        return item;
    });

    let prices = {};

   
    plans.map(item => {
        // console.log('p_'+state+'_'+avg_year_1+'_'+item)
        let rate_1 = rates_mode['p_'+state+'_'+avg_year_1+'_'+item]?rates_mode['p_'+state+'_'+avg_year_1+'_'+item]:0;
        let extra = 0
        if(add_extra > 0){
            let big_rate = (rates['family_big']['p_'+state+'_'+avg_year_1+'_'+item]?rates['family_big']['p_'+state+'_'+avg_year_1+'_'+item] : rate_1 ) 
            // debugger;
            // console.log('big_rate', big_rate);
            // console.log('big_rate', rate_1);
            let big_rate_diff = big_rate ;
            // console.log('big_rate_diff', big_rate_diff);
            extra  = big_rate_diff * add_extra;

            // console.log(rate_1, add_extra, extra, rates['self']['p_'+state+'_'+add_extra_group+'_'+item], 'p_'+state+'_'+add_extra_group+'_'+item, rates['self'])
        }
        prices['p_'+item] = rate_1 + extra;
        return item;
    });

    riders.map(item => {
        let extra = 0
        // console.log('r_'+state+'_'+avg_year_1+'_'+item);
        // console.log(rates_mode['r_'+state+'_'+avg_year_1+'_'+item]);
        let rate_1 = rates_mode['r_'+state+'_'+avg_year_1+'_'+item]?rates_mode['r_'+state+'_'+avg_year_1+'_'+item]:0;
        if(add_extra > 0){

            let big_rate = (rates['family_big']['r_'+state+'_'+avg_year_1+'_'+item]?rates['family_big']['r_'+state+'_'+avg_year_1+'_'+item] : rate_1);
            let big_rate_diff = big_rate ;
            extra  = big_rate_diff * add_extra;
        }
        prices['r_'+item] = rate_1 + extra;
        return item;
    });

    // console.log(prices);
    //debugger;
    return prices;
    
}

export default getCachedRates;