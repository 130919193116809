const q_data = require('./data/cached_questions/cached_questions.json');


const getCachedQuestions = function(state, plans)
{

    state = (state||'').toUpperCase();
    let list = [];
    if(!plans)return [];
    plans.map(function(plan){
        let q_list = q_data.question_array[state]['plan_'+plan];
        q_list.map((item)=>{
            if(list.indexOf(item)>-1){}else{
                list.push(item);
            }
        })
    })
    let q= [];
    list.map((item)=>{
        if(q_data.questions[item])
            q.push(q_data.questions[item]);
    });
    return q;
}

export default getCachedQuestions;
