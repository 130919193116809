/**
 * External dependencies
 */
 import React, {Component} from 'react';
import {Link} from 'react-router-dom';
 
 /**
  * Layouts
  */
 import Wrapper from '../layouts/wrapper';
 import Header from '../layouts/header';
 import Section from '../layouts/section';
 import poster from '../components/poster';
 import jquery from 'jquery';
 import constants from '../components/constants';
 import Button from '../components/button/button';
 
 import 'fancy-notifications';
 import {Plugins} from '@capacitor/core';
import { GoBack } from '../components/GoBack';
 
 const {FancyNotifications} = Plugins;
 
 const url = constants.api_url;
 
 async function updateBadgeCount(cnt) {
  if (window.location.host == 'localhost:3000' || 'app.mhlez.com' == window.location.host || 'testapp.mhlez.com' == window.location.host) return;
 
   const check = await FancyNotifications.hasPermission();
   if (check.value) {
     FancyNotifications.setBadgeCount({count: cnt});
   } else {
     const request = await FancyNotifications.requestPermission();
     if (request.value) {
       FancyNotifications.setBadgeCount({count: cnt});
     } else {
       // User failed to grant permission show some dialog
     }
   }
 }
 
 const uploadItem = function (item, obj) {
   let agentCode = localStorage.getItem('agent_code');
   jquery('#row_' + item).addClass('processing');
   // jquery('#row_'+item +' .ff1').hide();
   // jquery('#row_'+item +' .ff2').show();
 
   poster
     .postData(
       url + 'saveData.php',
       {
         mode: 'done',
         editMode: 'no',
         agent_code: agentCode,
         data: obj,
         identifier: item,
       },
       true,
     )
     .then(resp => {
       if (typeof resp === 'string' && resp.indexOf('_NO_INTERNET_') == 0) {
         jquery('#row_' + item).removeClass('processing');
         // jquery('#row_'+item +' .ff1').hide();
         // jquery('#row_'+item +' .ff2').show();
         alert('Failed to upload! ' + resp.replace('_NO_INTERNET_|', '') + ' ' + item);
         return;
       }
 
       let pending_updates = localStorage.getItem('pending_updates');
       if (!pending_updates) pending_updates = '[]';
       pending_updates = JSON.parse(pending_updates);
       let i = pending_updates.indexOf(item);
       if (i > -1) {
         pending_updates.splice(i, 1);
       }
       updateBadgeCount(pending_updates.length);
       pending_updates = JSON.stringify(pending_updates);
       localStorage.setItem('pending_updates', pending_updates);
 
       jquery('#row_' + item).remove();
     });
 };
 
 const getinfoById = function (item, draftMode ) {
     let key = 'pending_' + item
     if(draftMode )
     {
        key = 'draft_' + item
     }
   let g = localStorage.getItem(key);
   g = JSON.parse(g || '{}');
   return g;
 };
 
 const PendingRow = ({item, noUpload, draftMode }) => {
   let obj = getinfoById(item, draftMode );
   if (!obj) return false;
   return (
     <div
       className="list-group-item upload_row"
       style={{padding: 10}}
       id={'row_' + item}>
       <div>
         <h4 className="p-0 m-0">
           {obj.first_name} {obj.last_name}
         </h4>
         <h6>
           {obj.city}, {obj.state}
         </h6>
       </div>
       <div className="ff2">
         <h3>Processing...</h3>
       </div>
       <div className="ff1">
           {noUpload ? <a
           href="#"
           className=" "
           onClick={e => {
             e.preventDefault();
             console.log(item, obj);
             let unique_identifier  = localStorage.getItem('unique_identifier');
             if(unique_identifier)
             {
                
               
               window
                              .swal('You currently have an ongoing application. You can either save the ongoing one as a draft and resume this draft application. Or alternatively discard the ongoing one and resume this draft application.', {
                                dangerMode:false, 
                                className: "noflexbtns",

                                buttons: {
                                  confirm: {
                                  text: 'Discard and resume',
                                  value: 'resume',
                                  visible: true,
                                  className: "",
                                  closeModal: true,
                                },
                                other: {
                                  text: "Save as draft and resume",
                                  value: 'savedraft',
                                  visible: true,
                                  className: "",
                                  closeModal: true,
                                } ,
                                cancel: {
                                  text: "Cancel",
                                  value: 'cancel',
                                  visible: true,
                                  className: "",
                                  closeModal: true,
                                } },
                              })
                              .then(value => {
                                if(value === 'resume')
                                {
                                  let pending_drafts = localStorage.getItem('pending_drafts');
                                  if (!pending_drafts) pending_drafts = '[]';
                                  pending_drafts = JSON.parse(pending_drafts);
                                  let i = pending_drafts.indexOf(item);
                                  if (i > -1) {
                                    pending_drafts.splice(i, 1);
                                  }
                                  pending_drafts = JSON.stringify(pending_drafts);
                                  localStorage.setItem('pending_drafts', pending_drafts);
                                  
                                  let agentCode = localStorage.getItem('agent_code');
                                  localStorage.setItem('using_agent', agentCode);
                                  localStorage.setItem('unique_identifier', item);
                                  localStorage.setItem('step_data5', JSON.stringify(obj));
                                  let mode = obj.mode || 'individual';
                                  window.location.href = '/mode/'+mode+'/steps/1';
                                }else if(value == 'savedraft'){
                                  let unique_id = localStorage.getItem('unique_identifier');
                                  let pending_drafts = localStorage.getItem('pending_drafts');
                                  if (!pending_drafts) pending_drafts = '[]';
                                  pending_drafts = JSON.parse(pending_drafts);
          
                                  pending_drafts.push(unique_id);
                                  localStorage.setItem(
                                      'pending_drafts',
                                      JSON.stringify(pending_drafts),
                                  );
                                  localStorage.setItem(
                                      'draft_' + unique_id,
                                      JSON.stringify( JSON.parse(localStorage.getItem('step_data5'))),
                                  );
                                  localStorage.setItem('unique_identifier', '');
                                  localStorage.setItem('step_data5', '');
                                  localStorage.setItem('using_agent', '');
                                  localStorage.setItem('editMode', '');
          
                                  let agent = localStorage.getItem('using_agent');
                                  window.swal('Success','Application Saved As Draft! Resuming this failed one', {
                                    className: "noflexbtns",
                                  });
                                    pending_drafts = localStorage.getItem('pending_drafts');
                                  if (!pending_drafts) pending_drafts = '[]';
                                  pending_drafts = JSON.parse(pending_drafts);
                                  let i = pending_drafts.indexOf(item);
                                  if (i > -1) {
                                    pending_drafts.splice(i, 1);
                                  }
                                  pending_drafts = JSON.stringify(pending_drafts);
                                  localStorage.setItem('pending_drafts', pending_drafts);
                                  
                                  let agentCode = localStorage.getItem('agent_code');
                                  localStorage.setItem('using_agent', agentCode);
                                  localStorage.setItem('unique_identifier', item);
                                  localStorage.setItem('step_data5', JSON.stringify(obj));
                                  let mode = obj.mode || 'individual';
                                  window.location.href = '/mode/'+mode+'/steps/1';

                                }

                              });

               return;
             }
             let pending_drafts = localStorage.getItem('pending_drafts');
             if (!pending_drafts) pending_drafts = '[]';
             pending_drafts = JSON.parse(pending_drafts);
             let i = pending_drafts.indexOf(item);
             if (i > -1) {
               pending_drafts.splice(i, 1);
             }
             pending_drafts = JSON.stringify(pending_drafts);
             localStorage.setItem('pending_drafts', pending_drafts);
             
             let agentCode = localStorage.getItem('agent_code');
             localStorage.setItem('using_agent', agentCode);
             localStorage.setItem('unique_identifier', item);
             localStorage.setItem('step_data5', JSON.stringify(obj));
             let mode = obj.mode || 'individual';
             window.location.href = '/mode/'+mode+'/steps/1';
 
           }}>
           Resume
         </a> : <a
           href="#"
           className="upload_icon_in_pendlist"
           onClick={e => {
             e.preventDefault();
             uploadItem(item, obj);
           }}>
           <img src={require('../assets/new_icons/upload.png').default} />
         </a>}
         
       </div>
     </div>
   );
 };
 
 class PendingSubmissions extends Component {
   state = {};
   componentDidMount() {
     let pending_updates = localStorage.getItem('pending_updates');
     if (!pending_updates) pending_updates = '[]';
     pending_updates = JSON.parse(pending_updates);
 


     let pending_drafts = localStorage.getItem('pending_drafts');
     if (!pending_drafts) pending_drafts = '[]';
     pending_drafts = JSON.parse(pending_drafts);
 


     // console.log(pending_updates)
     this.setState({
       pending_updates: pending_updates,
       pending_drafts : pending_drafts
     });
     

   }
   render() {
     let {pending_updates, pending_drafts} = this.state;
     return (
       <Wrapper>
         <div className="header_main">
           <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
             <div className="row">
               <div
                 className="col-4"
                 style={{
                   flexDirection: 'row',
                   display: 'flex',
                   alignItems: 'center',
                 }}>
                 <img
                   alt="logo"
                   src={require('../assets/images/logo_top.png').default}
                 />
               </div>
             </div>
           </div>
         </div>
 
         <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
           <Section className="section--primary">
             <Section.Head cls="p-0">
               {/* <h1 className="text-center" style={{minHeight: 'auto'}}>
                Applications
               </h1> */}

               <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                   Applications
                </h1>
                <GoBack />
              </div>


               <div className="mb30"></div>
               <div className="card shopping-cart shadow_card"      style={{margin: 16, background: 'none'}}>
               <h2 className='text-center text-bold'>Pending Uploads</h2>

                 {!pending_updates || pending_updates.length == 0 ? (
                   <div
                    
                     style={{
                      fontStyle: 'italic',
                      textAlign: 'center'
                     }}>
                     No pending applications to upload.
                   </div>
                 ) : (
                   false
                 )}
 
                 <div className="list-group">
                   {pending_updates &&
                     pending_updates.map((item, i) => {
                       return <PendingRow key={item} item={item} />;
                     })}
                 </div>



                 <br />
                 <br />
<br />

                 <h2 className='text-center text-bold'>Drafts</h2>

                 {!pending_drafts || pending_drafts.length == 0 ? (
                   <div
                      
                     style={{
                      fontStyle: 'italic',
                      textAlign: 'center'
                     }}>
                     No pending drafts to complete.
                   </div>
                 ) : (
                   false
                 )}
 


                 <div className="list-group">
                   {pending_drafts &&
                     pending_drafts.map((item, i) => {
                        //  console.log(item)
                       return <PendingRow key={item} draftMode noUpload item={item} />;
                     })}
                 </div>
               </div>
             </Section.Head>
 
             <Section.Foot>
               {pending_updates && pending_updates.length>  0 ? (
                 <Button
                   onClick={e => {
                     e.preventDefault();
                     if (this.state.uploading_all) return false;
                     this.setState({
                       uploading_all: true,
                     });
                     if (pending_updates && pending_updates.length) {
                       let f = pending_updates.length;
                       for (let i = 0; i < f; i++) {
                         uploadItem(
                           pending_updates[i],
                           getinfoById(pending_updates[i]),
                         );
                       }
                     }
                   }}
                   size="btn_kk ">
                   <img
                     src={require('../assets/new_icons/upload.png').default}
                   />
                   {this.state.uploading_all ? 'Processing..' : 'Upload All'}
                 </Button>
               ) : (
                 false
               )}
 
               <Button
                 onClick={e => {
                   window.location.href = '/inner';
                 }}
                 size="btn_kk ">
                 
                 <span style={{
                  display: 'inline-block',
                  maxWidth: 30,
                  borderRadius: '50%',
                  background: '#fff',color:'#000',
                  marginRight: 10,
                  height: 30,
                  width: 30,
                }} className="p-0">
                 <i className="fa fa-home" style={{margin:0,top:0}} aria-hidden="true"></i>

                 </span>
                 Homepage
               </Button>
             </Section.Foot>
           </Section>
         </div>
       </Wrapper>
     );
   }
 }
 
 export default PendingSubmissions;
 