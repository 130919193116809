/**
 * External dependencies
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

/**
 * Layouts
 */
import Wrapper from '../layouts/wrapper';
import Header from '../layouts/header';
import Section from '../layouts/section';

class Submitted extends Component {
  componentDidMount() {
    localStorage.setItem('unique_identifier', '');
    localStorage.setItem('step_data5', '');
    localStorage.setItem('using_agent', '');
    localStorage.setItem('editMode', '');

    // let agent = localStorage.getItem('using_agent');
    // if (agent) {
      // setTimeout(() => {
        // window.location.href = '/inner/agent/' + agent;
      // }, 5000);
    // } else {
      setTimeout(() => {
        window.location.href = '/inner';
      }, 3000);
    // }
  }
  render() {
    return (
      <Wrapper className="lastpagewrapper">
        <Header>
          <Link to="/" className="logo">
            <img src={require('../assets/logo.png').default} alt="" />
          </Link>
        </Header>

        <Section className="section--primary">
          <Section.Head>
            <h1>Thanks for submitting your application</h1>

            <p>
              application(s) have been submitted, you should receive written
              confirmation from company within 7-10 days. If you have any
              questions regarding your application please call us anytime at{' '}
              <a href="tel:8007523419">800-752-3419</a>
            </p>
            <div className="text-center">
              <a  href="#" className='pill orange-pill' style={{fontWeight:'bold',display:'inline-block',textDecoration:"none", fontSize:18, marginTop:30}} onClick={(e)=>{
                e.preventDefault();
                localStorage.setItem('unique_identifier', '');
                localStorage.setItem('step_data5', '');
                localStorage.setItem('using_agent', '');
                localStorage.setItem('editMode', '');
                window.location.href = '/inner';
              }}>Return to <span className='fa fa-home'></span></a>
              {/* <img
                src={require('../assets/cookie.png').default}
                style={{width: 60, height: 60}}
                onClick={() => {
                 
                }}
              /> */}
            </div>
          </Section.Head>

          <Section.Foot>
            <img src={require('../assets/images/img2.png').default} alt="" />
          </Section.Foot>
        </Section>
      </Wrapper>
    );
  }
}

export default Submitted;
