/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Field from '../components/field/field';

import Wrapper from '../layouts/wrapper';
import Section from '../layouts/section';
import constants from '../components/constants';
import postData from '../components/poster';
import { GoBack } from '../components/GoBack';
const url = constants.api_url;

const getinfoById = function (item, draftMode) {
  let g = JSON.parse(item || '{}');
  return g;
};

const getlinksById = function (item) {
  if(!item)
    return [];
  let g = JSON.parse(item || '{}');

  if (!g.length || g.length === 0) {
    if( typeof g.length === 'undefined' && g.message )
    {
      return [g.message];
    }
    return [];
  }
  // g = g.map(function (it, i) {
  //   return it.applicantDocumentURL;
  // // });
  // console.log(g);

  return g;
};

const PendingRow = ({item, noUpload, draftMode}) => {
  let obj = getinfoById(item.data, draftMode);
  // let obj = item;
  if (!item) return false;
  let links = getlinksById(item.returned_msg);
  //  let obj = item;
  return (
    <div className="list-group-item application_row" id={'row_' + item.id}>
      <div>
        <h4 className="p-0 m-0">
          {obj.first_name} {obj.last_name}
        </h4>
        <h6>
          {obj.city}, {obj.state}
        </h6>
      </div>
      <div className="ff1"></div>
      <div className="divider"></div>
      <h6>Submitted on <span className="color-orange">{item.created_on}</span></h6>
      <div className="divider"></div>

      {links.map((link, i) => {
        return (
          <h6 className="text-danger" key={i}>
            {link}
          </h6>
        );
      })}
      <div className="divider"></div>
      <a
           href="#"
           className=" "
           onClick={e => {
             e.preventDefault();
            //  console.log(item, obj);
             let unique_identifier  = localStorage.getItem('unique_identifier');
             if(unique_identifier)
             {
                


               window
                              .swal('You currently have an ongoing application. You can either save the ongoing one as a draft and resume this failed application. Or alternatively discard the ongoing one and resume this failed application.', {
                                dangerMode:false, 
                                className: "noflexbtns",

                                buttons: {
                                  confirm: {
                                  text: 'Discard and resume',
                                  value: 'resume',
                                  visible: true,
                                  className: "",
                                  closeModal: true,
                                },
                                other: {
                                  text: "Save as draft and resume",
                                  value: 'savedraft',
                                  visible: true,
                                  className: "",
                                  closeModal: true,
                                } ,
                                cancel: {
                                  text: "Cancel",
                                  value: 'cancel',
                                  visible: true,
                                  className: "",
                                  closeModal: true,
                                } },
                              })
                              .then(value => {
                                if(value === 'resume')
                                {
                                  let agentCode = localStorage.getItem('agent_code');
                                  localStorage.setItem('using_agent', agentCode);
                                  localStorage.setItem('unique_identifier', item.identifier);
                                  localStorage.setItem('step_data5', item.data);
                                  let mode = obj.mode || 'individual';
                                  window.location.href = '/mode/'+mode+'/steps/1';
                                }else if(value == 'savedraft'){
                                  let unique_id = localStorage.getItem('unique_identifier');
                                  let pending_drafts = localStorage.getItem('pending_drafts');
                                  if (!pending_drafts) pending_drafts = '[]';
                                  pending_drafts = JSON.parse(pending_drafts);
          
                                  pending_drafts.push(unique_id);
                                  localStorage.setItem(
                                      'pending_drafts',
                                      JSON.stringify(pending_drafts),
                                  );
                                  localStorage.setItem(
                                      'draft_' + unique_id,
                                      JSON.stringify( JSON.parse(localStorage.getItem('step_data5'))),
                                  );
                                  localStorage.setItem('unique_identifier', '');
                                  localStorage.setItem('step_data5', '');
                                  localStorage.setItem('using_agent', '');
                                  localStorage.setItem('editMode', '');
          
                                  let agent = localStorage.getItem('using_agent');
                                  window.swal('Success', 'Application Saved As Draft! Resuming this failed one', {
                                    className: "noflexbtns",
                                  });
                                  let agentCode = localStorage.getItem('agent_code');
                                  localStorage.setItem('using_agent', agentCode);
                                  localStorage.setItem('unique_identifier', item.identifier);
                                  localStorage.setItem('step_data5', item.data);
                                  let mode = obj.mode || 'individual';
                                  window.location.href = '/mode/'+mode+'/steps/1';

                                }

                              });


               return;
             }
            //  let pending_drafts = localStorage.getItem('pending_drafts');
            //  if (!pending_drafts) pending_drafts = '[]';
            //  pending_drafts = JSON.parse(pending_drafts);
            //  let i = pending_drafts.indexOf(item);
            //  if (i > -1) {
              //  pending_drafts.splice(i, 1);
            //  }
            //  pending_drafts = JSON.stringify(pending_drafts);
            //  localStorage.setItem('pending_drafts', pending_drafts);
             
             let agentCode = localStorage.getItem('agent_code');
             localStorage.setItem('using_agent', agentCode);
             localStorage.setItem('unique_identifier', item.identifier);
             localStorage.setItem('step_data5', item.data);
             let mode = obj.mode || 'individual';
             window.location.href = '/mode/'+mode+'/steps/1';
 
           }}>
            <i className="fa fa-repeat" aria-hidden="true"></i> Resume
          </a>
    </div>
  );
};

class FailedSubmissions extends Component {
  state = {
    data: [],
    loaded: false,
    yet_to_load: true,
  };
  componentDidMount() {
    let auth = localStorage.getItem('agent_token');
    postData
      .getData(
        url + 'listCompletedAgent.php?submitStatus=pending&token=' + auth,
        {},
        false,
        false,
      )
      .then(data => {
        if (data.ok && data.forms_list) {
          this.setState({
            data: data.forms_list,
            loaded: true,
            yet_to_load: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          loaded: true,
          yet_to_load: false,
        });
      });
  }
  render() {
    let {data, yet_to_load} = this.state;
    return (
      <Wrapper>
        <div className="header_main">
          <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  alt="logo"
                  src={require('../assets/images/logo_top.png').default}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                  Failed Applications
                </h1>
                <GoBack />
              </div>
            </Section.Head>
            <Section.Body>
            <div
                style={{margin: 16, background: 'none'}}>
            {
                  !yet_to_load && data.length > 0? 
                  <div className='form-group'>
                    
                    <Field value={this.state.search || ''} id="search" name="search" placeholder="Search" onChange={(e, b)=>{
                      this.setState({
                        [e]:b
                      })

                    }} />
                  </div>
                  : false
                }
                </div>


              <div
                style={{margin: 16, background: 'none'}}
                className="card shopping-cart shadow_card">
                {/* <h2 className='text-center text-bold'>Pending Uploads</h2> */}

                {!data || data.length == 0 ? yet_to_load ?   <div style={{textAlign:"center"}}>
                  <img src={require('../assets/loading.gif').default}  />
                  </div> : (
                  <div
                    className="alert alert-warning"
                    style={{
                      position: 'relative',
                      padding: '20px 20px',
                      border: '1px solid transparent',
                      borderRadius: 10,
                      color: '#664d03',
                      backgroundColor: '#fff3cd',
                      borderColor: '#ffecb5',
                    }}>
                    {'No Failed applications found.'}
                  </div>
                ) : (
                  false
                )}

            

                <div className="list-group">
                  {data &&
                    data.map((item, i) => {
                      if( this.state.search)
                      {
                        if( item.data.toLowerCase().indexOf(this.state.search) > -1 )
                        {
                          //
                        }else{
                          return false;
                        }
                      }

                      return <PendingRow key={item.id} item={item} />;
                    })}
                </div>
              </div>
            </Section.Body>

            <Section.Foot></Section.Foot>
          </Section>
        </div>
      </Wrapper>
    );
  }
}

export default FailedSubmissions;
