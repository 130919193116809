/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Field from '../components/field/field';
import { Browser } from '@capacitor/browser';

import Wrapper from '../layouts/wrapper';
import Section from '../layouts/section';
import constants from '../components/constants';
import postData from '../components/poster';
import { GoBack } from '../components/GoBack';
const url = constants.api_url;

const getinfoById = function (item, draftMode) {
  let g = JSON.parse(item || '{}');
  return g;
};

const openUrl = async (e, url) => {
  if (window.location.host == 'localhost:3000' || 'app.mhlez.com' == window.location.host || 'testapp.mhlez.com' == window.location.host) return true;
  e.preventDefault();
  await Browser.open({ url: url, presentationStyle: 'popover', toolbarColor: '#fda21b' });
};

const getlinksById = function (item) {
  let g = JSON.parse(item || '{}');

  if (g.length == 0) {
    return [];
  }
  g = g.map(function (it, i) {
    return it.applicantDocumentURL;
  });
  console.log(g);

  return g;
};

const PendingRow = ({item, noUpload, draftMode}) => {
  let obj = getinfoById(item.data, draftMode);
  // let obj = item;
  if (!item) return false;
  let links = getlinksById(item.returned_msg);
  //  let obj = item;
  return (
    <div className="list-group-item application_row" id={'row_' + item.id}>
      <div>
        <h4 className="p-0 m-0">
          {obj.first_name} {obj.last_name}
        </h4>
        <h6>
          {obj.city}, {obj.state}
        </h6>
      </div>
      <div className="ff1"></div>
      <div className="divider"></div>
      <h6>Submitted on <span className="color-orange">{item.created_on}</span></h6>
      <div className="divider"></div>

      {links.map((link, i) => {
        return (
          <a key={i} href={link} className=" " target="_blank" onClick={e=>{
            openUrl(e, link);
          }}>
            <i className="fa fa-download"></i> Get PDF
          </a>
        );
      })}
    </div>
  );
};

class PendingSubmissions extends Component {
  state = {
    data: [],
    loaded: false,
    yet_to_load: true,
  };
  componentDidMount() {
    let auth = localStorage.getItem('agent_token');
    postData
      .getData(
        url + 'listCompletedAgent.php?submitStatus=completed&token=' + auth,
        {},
        false,
        false,
      )
      .then(data => {
        if (data.ok && data.forms_list) {
          this.setState({
            data: data.forms_list,
            loaded: true,
            yet_to_load: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          loaded: true,
          yet_to_load: false,
        });
      });
  }
  render() {
    let {data, yet_to_load} = this.state;
    return (
      <Wrapper>
        <div className="header_main">
          <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  alt="logo"
                  src={require('../assets/images/logo_top.png').default}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                  Successful Applications
                </h1>
                <GoBack />
              </div>
            </Section.Head>
            <Section.Body>
            <div
                style={{margin: 16, background: 'none'}}>
            {
                  !yet_to_load && data.length > 0? 
                  <div className='form-group'>
                    
                    <Field value={this.state.search || ''} id="search" name="search" placeholder="Search" onChange={(e, b)=>{
                      this.setState({
                        [e]:b
                      })

                    }} />
                  </div>
                  : false
                }
                </div>


              
              <div
                style={{margin: 16, background: 'none'}}
                className="card shopping-cart shadow_card">
                {/* <h2 className='text-center text-bold'>Pending Uploads</h2> */}

                {!data || data.length == 0 ? yet_to_load ?   <div style={{textAlign:"center"}}>
                  <img src={require('../assets/loading.gif').default}  />
                  </div>: (
                  <div
                    className="alert alert-warning"
                    style={{
                      position: 'relative',
                      padding: '20px 20px',
                      border: '1px solid transparent',
                      borderRadius: 10,
                      color: '#664d03',
                      backgroundColor: '#fff3cd',
                      borderColor: '#ffecb5',
                    }}>
                    {'No Successful applications found.'}
                  </div>
                ) : (
                  false
                )}

                <div className="list-group">
                  {data &&
                    data.map((item, i) => {
                      if( this.state.search)
                      {
                        if( item.data.toLowerCase().indexOf(this.state.search) > -1 )
                        {
                          //
                        }else{
                          return false;
                        }
                      }
                      return <PendingRow key={item.id} item={item} />;
                    })}
                </div>
              </div>
            </Section.Body>

            <Section.Foot></Section.Foot>
          </Section>
        </div>
      </Wrapper>
    );
  }
}

export default PendingSubmissions;
