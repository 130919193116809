/**
 * External dependencies
 */
 import React, {Component} from 'react';
 import cx from 'classnames';
import { Browser } from '@capacitor/browser';
 
const openUrl = async (e, url) => {
	if (window.location.host == 'localhost:3000' || 'testapp.mhlez.com' == window.location.host || 'app.mhlez.com' == window.location.host) return true;
	e.preventDefault();
	await Browser.open({ url: url, presentationStyle: 'popover', toolbarColor: '#fda21b' });
  };
 class Radio extends Component {
   handleChange = e => {
	 console.log(
		this.props.trueFalseMode
		 ? e.target.checked
		   ? this.props.trueRepresentative ? this.props.trueRepresentative : true
		   : false
		 : e.target.value

	 )
	 this.props.onChange(
	   e.target.name,
	   this.props.trueFalseMode
		 ? e.target.checked
		   ? this.props.trueRepresentative ? this.props.trueRepresentative : true
		   : false
		 : e.target.value,
	 );
   };
 
   render() {
	 let {
	   name,
	   id,
	   text,
	   value,
	   hint,
	   price,
	   defaultChecked,
	   disabled,
	   className,
	   hasMinHeight,
	   type,
	//    standout,
	   subtext,
	   showBrochure,
	   brochureState,
	   brochurePlan
 

	 } = this.props;
	 // console.log(standout)
	 if(!price)
	 {
		 price  ='';
	 }
	 return (
	   <div
		 className={cx('radio', {
		   [`${className}`]: className,
		 })}>
		 <input
		   type={type || 'radio'}
		   name={name || id}
		   id={id}
		   disabled={disabled}
		   value={value||text}
		   onChange={this.handleChange}
		//    checked ={checked === false || checked === true ? checked : false}
		   defaultChecked={ defaultChecked}
		//    checked={defaultChecked}
		 />
 
		 <label
		   className={
			 (hasMinHeight ? 'min_height_radio' : ' ') +
			 (hasMinHeight && !hint ? ' radio_circle_go_down' : ' ')
		   }
		   htmlFor={id}>
		   {text}{' '}
		   {subtext ? (
			 <small
			   style={{
				 fontSize: 11,
				 display: 'block',
				 // paddingLeft: 14,
				 fontWeight: 'bold',
			   }}>
			   {subtext}
			 </small>
		   ) : (
			 false
		   )}
		   

		   {hint && <span>{hint}</span>}
		   
		   {/* { */}
			{/* //    showBrochure ? 'te':false */}
		   {/* } */}
		   {price ? (
			 <span className={'radio__price '}>
			   {/* {price.split('.')[0]}.<span>{price.split('.')[1]}</span> */}
			   {price.split('.')[0]}.{price.split('.')[1]}
			 </span>
		   ) : (
			 false
		   )}
		   {
			   showBrochure ? 
			   <a onClick={e=>{
				openUrl(e, "https://app.mhlez.com/api/manhattan.api.php?action=brochure&product_id="+brochurePlan+"&state_code="+(brochureState||'').toUpperCase());
			  }} href={"https://app.mhlez.com/api/manhattan.api.php?action=brochure&product_id="+brochurePlan+"&state_code="+(brochureState||'').toUpperCase()} target="_blank" style={{
				fontSize: 11,
				float:'right',
				position: 'absolute',
    			right: '20px',
				fontWeight: 'bold',
			  }}><img src={require("../../assets/brochure.png").default} className="brochure_img" /></a>
				   : false
		   }
		 </label>
	   </div>
	 );
   }
 }
 
 export default Radio;
 