import React, { Component } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../layouts/wrapper";
import Section from "../layouts/section";
class Menu extends Component {
  state = {};
  
  render() {
    
    return (
      <div className={"sliding_menu_wrap "+(this.props.isVisible ? ' visible ': '') }>
      <div className="sliding_menu_body">

      
      <Wrapper>
        

        <div style={{ backgroundColor: "var(--color-mode-bg)" }}>
          <Section className="section--primary p-0">
            <Section.Head cls="p-0">
              <div className="menu-sidebar sidebar">
                <ul className="menu-sidebar-items">
                  <li>
                    <Link
                      to="/inner"
                      onClick={e=>{
                        if(this.props.onClose)
                        {
                          e.preventDefault();
                          this.props.onClose();
                        } 
                      }}
                      style={{ borderBottom: 0, marginBottom: 15, color: 'var(--color-mode-accent)' }}
                    >
                      <span className="fa fa-chevron-left"></span>Go Back
                    </Link>
                  </li>
                </ul>
                {
                  this.props.hideMain ? false : <>
                <div
                  className="card shopping-cart shadow_card"
                  style={{
                    margin: 10,
                    // background: "none",
                    overflow:'hidden',
                    padding: 0,
                    marginTop: 0,
                  }}
                >
                  <ul className="menu-sidebar-items">
                    <li>
                      <Link to="/pendingSubmissions">
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        Pending Uploads / Partial Apps
                      </Link>
                    </li>
                    <li>
                      <Link to="/completedSubmissions">
                        <i className="fa fa-check" aria-hidden="true"></i>
                        Completed Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/failedSubmissions">
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        Failed Uploads
                      </Link>
                    </li>{" "}
                    <li>
                      <Link to="/products">
                        <i className="fa fa-list" aria-hidden="true"></i>
                        Products
                      </Link>
                    </li><li>
                      <Link to="/templates">
                        <i className="fa fa-sticky-note" aria-hidden="true"></i>
                        Templates
                      </Link>
                    </li>
                    {/* <li style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                      <Link to="/colors" style={{borderRight:'1px solid #ededed'}}>
                        <i className="fa fa-paint-brush" aria-hidden="true"></i>
                        Colors
                      </Link> */}
                      {/* </li> */}
                    <li>
                      <Link to="/settings" style={{ borderBottom: 0 }}>
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        Colors & Settings
                      </Link>
                    </li>
                  </ul>
                </div>

                <div style={{ textAlign: "center", marginTop: 30 }}>
                  <small className="mt-1">
                    <a
                      onClick={() => {
                        window.open("https://testapp.mhlez.com/");
                      }}
                    >
                       Desktop Login
                    </a>
                  </small>
                  <small className="mt-1">&nbsp;|&nbsp;</small>
                  <small className="mt-1">
                    <a
                      onClick={() => {
                        window.open("https://testapp.mhlez.com/panel/admin-login");
                      }}
                    >
                       Admin Login
                    </a>
                  </small>
                </div></>}
                {
                  this.props.renderLinks?  
                    this.props.hideMain ? false :   this.props.renderLinks : false
                }


{
                  this.props.hideMain ? false : <>
                {
                  this.props.agent_info ? 
                  <div style={{
                    textDecoration: 'none',
                    color: 'rgb(114, 114, 114)',
                    textAlign:"left",
                    fontSize:14,
                    width: 230,
                    margin:'0 auto'
                  }}>
                    <br />
                     
                     
                  <strong style={{fontWeight:600}}>Logged in as:</strong>
                  <br />
                  Name: <i>{this.props.agent_info.name}</i><br />
                  {/* Email: {this.props.agent_info.email}<br /> */}
                  Agent No: <i>{this.props.agent_info.agent_code}</i><br />
                
                  </div> : false
                }
                <br />
                </>}
                {
                  this.props.renderDropdown ? this.props.hideDropdown ? false : this.props.renderDropdown : false
                }



              </div>
            </Section.Head>
          </Section>
        </div>
      </Wrapper>
      </div>
      <a className="slidingMenuBaba" href="#" onClick={e=>{
                        e.preventDefault();
                        this.props.onClose && this.props.onClose();
                      }}></a>
      </div>
    );
  }
}

export default Menu;
